
import { Component, Prop, Vue } from 'vue-property-decorator';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { UserRightsEnum } from '@/enums/global/UserRights';
import HelpPage from '@/components/global/HelpPage.vue';

@Component({
    name: 'ClientTopBar',
    components: {
        HelpPage,
    },
})
export default class ClientTopBar extends Vue {
    @Prop({ required: true }) private title!: string | null;

    private areFiltersVisible = false;

    private get userRights() {
        return this.$store.getters['jwtData/userRights'];
    }

    public get canCreateClients() {
        return this.userRights.includes(UserRightsEnum.CREATE_CLIENT);
    }

    private showNewClientPopup() {
        EventBus.$emit(EventBusEvents.toggleNewClientModal, true);
    }
}
